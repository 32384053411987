// all data module


import axiosIns from "@/libs/axios";

const state = {
    expenseses: [],
    expensesByid: [],
    expensesess: [],
    categories: [],
    tableColumns: [
        {key: "title", label: "Τίτλος", sortable: true},
        {key: "datestamp", label: "Ημερομηνία", sortable: true},
        {key: "category.title", label: "Κατηγορία", sortable: true},
        {key: "project.title", label: "Εργο", sortable: false},
        {key: "amount", label: "Ποσο", sortable: true},
        {key: "paymentMethod", label: "Τρόπος πληρωμής", sortable: false},
        {key: "actions", label: ""},
    ],
};

const getters = {
    getByIdExpense:state=>state.expensesByid,
    getExpenseses: (state) => state.expenseses,
    getExpensesess: (state) => state.expensesess,
    getCategoriess: (state) => state.categories,

    getExpensesesTableColumns: (state) => state.tableColumns,
};


const actions = {
    async fetchExpensesesById({commit}, id) {
        const response = await axiosIns.get(`/expense/${id}`)
        commit('SET_EXPENSES_BY_ID', response.data)
    },
    async fetchExpensesesCategories({commit}, filters) {
        const response = await axiosIns.get(`/expense`, filters)


        commit('SET_EXPENSES', response.data)
    },
    // async fetchAllCategories({commit}, filters) {
    //     filters.params.rpp = 50;
    //     const response = await axiosIns.get(`/contacts`, filters)
    //
    //
    //
    //     commit('SET_CATEGORIES', response.data)
    // },

    async storeExpensesesCategory({commit}, data) {


        const response = await axiosIns.post(`/expense`, data)
        commit('ADD_EXPENSES_ROW', response.data)

    },
    updatePageSelectedOption({commit}, value = 1) {
        commit('UPDATE_PAGE_SELECTED_VALUE', value);
    },

    async deleteExpensesesCategory({commit}, data) {

        axiosIns.delete(`/expense/${data.item.id}`)
            .then(response => {
                commit('DELETE_EXPENSES', data)
            })
    },

};

const mutations = {
    SET_EXPENSES_BY_ID: (state, value) => (state.expensesByid = value),
    SET_EXPENSES: (state, value) => (state.expensesess = value),
    SET_CATEGORIES: (state, value) => {
        state.categories = value.data.map(el => {
            return {
                id: el.category?.id,
                title: el.category?.title,
            }
        })
    },
    UPDATE_PAGE_SELECTED_VALUE: (state, value) => (state.perPageSelectedOption = value),
    ADD_EXPENSES_ROW: async (state, data) => {
        const response = await axiosIns.get(`/expense`, {
            params: {
                "rpp":10,
                "orderBy": ["datestamp"],
                "sortBy": "desc"
            }
        })
        state.expensesess = response.data;
    },
    DELETE_EXPENSES: async (state, data) => {
        const response = await axiosIns.get(`/expense`, {
            params: {
                "rpp":10,
                "orderBy": ["datestamp"],
                "sortBy": "desc"
            }
        })
        state.expensesess = response.data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
