import axiosIns from "@/libs/axios";

const state = {
    dashboard: [],
}

const getters = {
    getDashboard: (state) => state.dashboard,
}

const actions = {
    async fetchDashboard({commit}) {

        const response = await axiosIns.get(`/dashboard`)

        commit('SET_DASHBOARD', response.data)
    },

}

const mutations = {
    SET_DASHBOARD: (state, data) => (state.dashboard = data),
}

export default {
    state, getters, actions, mutations,
}