import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import AppAuth from './app-auth'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import General from './general'
import CaldoGlobal from './global'
import SettingsTodo from "./global/todo";
import SettingsService from "./global/service";
import SettingMail from "./global/mail";
import SettingsProject from "./global/project";
import SettingsExpense from "./global/expense";
import SettingsCompany from "./global/company";
import SettingsCalendar from "./global/calendar";
import SettingPreference from "./global/preference";
import SettingsUser from "./global/personal";
// import SettingCategory from "./global/index";
import Notifications from "./global/notification";
import Contacts from './contact'
import Dashboard from './dashboard'
import Expenses from './expenses'
import Project from './project'
import Partners from './partners'
import Calendar from './calendar'
import Todos from './todo'
import User from './user'
import Logged from './logged'
import Company from './company'
import Report from './report'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        AppAuth,
        appConfig,
        Dashboard,
        verticalMenu,
        General,
        CaldoGlobal,
        Expenses,
        Partners,
        Calendar,
        Report,
        Todos,
        User,
        Contacts,
        Logged,
        Company,
        SettingMail,
        Notifications,
        Project,
        SettingsExpense,
        SettingsService,
        SettingsProject,
        SettingsCompany,
        SettingsUser,
        SettingPreference,
        SettingsCalendar,
        // SettingCategory,
        SettingsTodo,
    },
    strict: process.env.DEV,


})