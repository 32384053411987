import caldoSettings from '@/caldoSettings'
import axiosIns from "@/libs/axios";

const baseUrl = caldoSettings.baseUrl


const state = {
    loggedUser: null,
    loggedSurname: null,
    errors: null,
    appToken: null,
}

const getters = {
    getLoggedUser: (state) => (state.loggedUser),
    getLoggedSurname: (state) => (state.loggedSurname),
    getErrors: (state) => (state.errors),
    getAppToken: (state) => (state.appToken),
}

const actions = {
    // User Login
    async login({commit}, payload) {
        try {
            const response = await axiosIns.post(`${baseUrl}/login`, payload)
            commit('SET_LOGGED_USER', response.data)
        } catch (e) {
            commit('SET_ERRORS', e.response.data)
        }


    },

    async refreshTokens({commit}) {
        let refresh = localStorage.getItem('jwtRefresh')
        try {
            const response = await axiosIns.post(`${baseUrl}/refresh`, {
                refresh,
            })

            commit('SET_LOGGED_USER', response.data)
        } catch (e) {


        }

    },

    // User Logout
    async logout({commit}, payload) {
        const response = await axiosIns.post(`${baseUrl}/logout`, null)
        // if (response.data.message === '') {
        commit('LOGOUT')

        // }
    }

}

const mutations = {
    SET_LOGGED_USER: (state, data) => {
        state.loggedUser = data.name
        state.loggedSurname = data.surname
        state.appToken = data.access_token
        localStorage.setItem('appToken', data.access_token)
        localStorage.setItem('appRefresh', data.expires_in)

        // axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;


    },
    SET_ERRORS: (state, data) => {
        state.errors = data
    },
    LOGOUT: (state) => {
        state.loggedUser = null
        state.appToken = null
        localStorage.removeItem('appToken')
        localStorage.removeItem('userData')
        localStorage.removeItem('surnameData')
    }
}

export default {state, getters, actions, mutations}
