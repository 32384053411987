import axiosIns from "@/libs/axios";

const state = {
    contactSettingCategories: [],
    contactSettingAllCategories: [],
    notificationsSettings: {},
    counter: 0,
    counterUp: 0
}

const getters = {
    getContactSettingCategoriesR: (state) => (state.contactSettingCategories),
    // getNotificationSettings: (state) => (state.notificationsSettings),
}

const actions = {
    async fetchContactSettingCategories({commit}, page) {


        if (state.counter === 0) {
            ++state.counter;
            const response = await axiosIns.get(`/contact-category`, {
                params: {
                    page: page,
                    rpp: 10
                }
            })
            commit('SET_CONTACT_SETTING_CATEGORIES', response.data)
            --state.counter;
        }

    },

    async fetchContactAllSettingCategories({commit}) {
        const response = await axiosIns.get(`/contact-category`, {
            params: {
                rpp: 100
            }
        })

        commit('SET_CONTACT_ALL_SETTING_CATEGORIES', response.data)
    },

    async deleteContactSettingCategory({commit}, data) {

        axiosIns.delete(`/contact-category/${data.item.id}`)
            .then(response => {
                commit('DELETE_CONTACT_SETTING_CATEGORY', data)
            })
    },
    async storeContactSettingCategory({commit}, response) {


        commit('STORE_CONTACT_SETTING_CATEGORIES', response.data)
    },
    async updateContactSettingCategory({commit}, data) {

        const response = await axiosIns.put(`/contact-category/${data.id}`, {
            title: data.name
        })
        commit('UPDATE_CONTACT_SETTING_CATEGORIES', response.data)
    }
}
const fetchContactCategory = () => {
    if (state.counterUp === 0) {
        ++state.counterUp;
        return axiosIns.get(`/contact-category`, {
            params: {
                page: 1,
                rpp: 10
            }
        })

    }
}
const mutations = {


    SET_CONTACT_SETTING_CATEGORIES: (state, data) => (state.contactSettingCategories = data),
    SET_CONTACT_ALL_SETTING_CATEGORIES: (state, data) => (state.contactSettingAllCategories = data),
    STORE_CONTACT_SETTING_CATEGORIES: async (state, data) => {
        console.log('store')
        const response = await fetchContactCategory();
        state.counterUp = 0;
        state.contactSettingCategories = response.data;
    },
    UPDATE_CONTACT_SETTING_CATEGORIES: async (state, data) => {
        console.log('upd')
        const response = await fetchContactCategory();
        state.contactSettingCategories = response.data;
    },
    DELETE_CONTACT_SETTING_CATEGORY: async (state, data) => {
        const response = await fetchContactCategory();
        state.contactSettingCategories = response.data;
    }
}

export default {state, getters, actions, mutations}