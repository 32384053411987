// all data module


import axiosIns from "@/libs/axios";
import caldoSettings from "@/caldoSettings";

const state = {
    report: [],
    balance: [],
    clientList: [],
    projectList: [],
    taskList: [],
    expensesList: [],
    incomeService: []
};

const getters = {
    getReport: (state) => state.report,
    getBalance: (state) => state.balance,
    getClientList: (state) => state.clientList,
    getProjectList: (state) => state.projectList,
    getTaskList: (state) => state.taskList,
    getExpensesList: (state) => state.expensesList,
    getIncomeService: (state) => state.incomeService,
};


const actions = {
    async fetchPdfReport({commit}, params) {
        const response = await axiosIns.get('/report/contact-services-payments', {
            params: {
                'dateFrom': params.from,
                'dateTo': params.to,
                'contactId': params.contactId.id,
                'preflight': true,
            }
        })
        const url = `${caldoSettings.baseUrl}/report/contact-services-payments-handler/${(response.data.preFlightHash)}.pdf`;
        commit('GET_PDF_REPORT', url)
    },
    async fetchPdfBalance({commit}) {
        const response = await axiosIns.get('/report/contact-balance-list', {
            params: {
                'preflight': true,
            }
        })
        const url = `${caldoSettings.baseUrl}/report/contact-balance-list-handler/${(response.data.preFlightHash)}.pdf`;
        commit('GET_PDF_BALANCE', url)
    },

    async fetchPdfClientList({commit}, params) {
        const response = await axiosIns.get('/report/contact-list', {
            params: {
                'dateFrom': params.from,
                'status': params.balance,
                'dateTo': params.to,
                'preflight': true,
            }
        })
        const url = `${caldoSettings.baseUrl}/report/contact-list-handler/${(response.data.preFlightHash)}.pdf`;
        commit('GET_PDF_CLIENT_LIST', url)
    },

    async fetchPdfProjectList({commit}, params) {
        const response = await axiosIns.get('/report/project-list', {
            params: {
                'dateFrom': params.from,
                'paymentStatus': params.balance,
                'status': params.status,
                'dateTo': params.to,
                'preflight': true,
            }
        })
        const url = `${caldoSettings.baseUrl}/report/project-list-handler/${(response.data.preFlightHash)}.pdf`;
        commit('GET_PDF_PROJECT_LIST', url)
    },
    async fetchPdfTaskList({commit}, params) {
        const response = await axiosIns.get('/report/task-list', {
            params: {
                'dateFrom': params.from,
                'status': params.status,
                'partnerId': params.partnerId,
                'dateTo': params.to,
                'preflight': true,
            }
        })
        const url = `${caldoSettings.baseUrl}/report/task-list-handler/${(response.data.preFlightHash)}.pdf`;
        commit('GET_PDF_TASK_LIST', url)
    },
    async fetchPdfExpensesList({commit}, params) {
        const response = await axiosIns.get('/report/expenses-list', {
            params: {
                'dateFrom': params.from,
                'dateTo': params.to,
                'categoryId': params.categoryId,
                'preflight': true,
            }
        })
        const url = `${caldoSettings.baseUrl}/report/expenses-list-handler/${(response.data.preFlightHash)}.pdf`;
        commit('GET_PDF_EXPENSES_LIST', url)
    },
    async fetchPdfIncomeService({commit}, params) {
        const response = await axiosIns.get('/report/income-service-list', {
            params: {
                'dateFrom': params.from,
                'dateTo': params.to,
                'service': params.categoryId,
                'preflight': true,
            }
        })
        const url = `${caldoSettings.baseUrl}/report/income-service-list-handler/${(response.data.preFlightHash)}.pdf`;
        commit('GET_PDF_INCOME_SERVICE', url)
    },

};

const mutations = {
    GET_PDF_REPORT: (state, data) => state.report = data,
    GET_PDF_BALANCE: (state, data) => state.balance = data,
    GET_PDF_CLIENT_LIST: (state, data) => state.clientList = data,
    GET_PDF_PROJECT_LIST: (state, data) => state.projectList = data,
    GET_PDF_TASK_LIST: (state, data) => state.taskList = data,
    GET_PDF_EXPENSES_LIST: (state, data) => state.expensesList = data,
    GET_PDF_INCOME_SERVICE: (state, data) => state.incomeService = data

};

export default {
    state,
    getters,
    actions,
    mutations,
};
