import axiosIns from "@/libs/axios";

const state = {
    settings: [],
}

const getters = {
    getSettings: (state) => (state.settings),
}

const actions = {
    async fetchSettings({commit}) {
        const response = await axiosIns.get(`/service`)

        commit('SET_SETTINGS', response.data)
    },

    // async deleteContactCategory({commit}, data) {
    //
    //     console.log(data)
    //     axiosIns.delete(`/contact-category/${data.item.id}`)
    //         .then(response => {
    //             commit('DELETE_CONTACT_CATEGORY', data)
    //         })
    // },
    async storeSettings({commit}, data) {
        const response = await axiosIns.post(`/service`, data)
        commit('STORE_SETTINGS', response.data)
    },
    async storeSingleSettings({commit}, data) {
        const response = await axiosIns.post(`/single-service`, data)
        // commit('STORE_SETTINGS', response.data)
    },


}

const mutations = {
    SET_SETTINGS: (state, data) => (state.settings = data),
    STORE_SETTINGS: (state, data) => (state.settings = data),
    // UPDATE_CONTACT_CATEGORIES: (state, data) => _.find(state.contactCategories, {id: data.id}).title = data.title,
    // DELETE_CONTACT_CATEGORY: (state, data) => state.contactCategories.splice(data.index, 1)
}

export default {state, getters, actions, mutations}