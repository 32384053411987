export default {
    //baseUrl: 'https://demo2.beezio.app/api',
    //baseUrl: `https://${window.location.host}/api`,
    //new URL(location.href).searchParams.get('page')
    baseUrl: `https://${window.location.host==='localhost:8080'||window.location.host==='localhost:8081'?'demo1.beezio.app':window.location.host}/api`,


    payments: [
        {text: 'Μετρητά', value: 'Μετρητά'},
        {text: 'Κατάθεση σε τράπεζα', value: 'Κατάθεση σε τράπεζα'},
        {text: 'Κάρτα', value: 'Πιστωτική Κάρτα'},
        {text: 'Paypal', value: 'paypal'},
    ],

    statusOptions: [
        {text: 'Ολα'},
        {text: 'Ολοκληρωμένο', value: 'Ολοκληρωμένο'},
        {text: 'Σε εξέλιξη', value: 'Σε εξέλιξη'},
        {text: 'Σε αναμονή', value: 'Σε αναμονή'},
        {text: 'Ακυρωμένο', value: 'Ακυρωμένο'},
    ],
    intervalOptions: [
        {value: 'week', text: 'Εβδομάδα'},
        {value: 'month', text: 'Μήνα'},
        {value: 'semester', text: 'Εξάμηνο'},
        {value: 'year', text: 'Έτος'},
    ],
    months: [
        {value: 1, text: 'Ιανουάριος'},
        {value: 2, text: 'Φεβρουάριος'},
        {value: 3, text: 'Μάρτιος'},
        {value: 4, text: 'Απρίλιος'},
        {value: 5, text: 'Μάϊος'},
        {value: 6, text: 'Ιούνιος'},
        {value: 7, text: 'Ιούλιος'},
        {value: 8, text: 'Αύγουστος'},
        {value: 9, text: 'Σεπτέμβριος'},
        {value: 10, text: 'Οκτώβριος'},
        {value: 11, text: 'Νοέμβριος'},
        {value: 12, text: 'Δεκέμβριος'},
    ],
    monthsShort: [
        'Ιαν', 'Φεβ', 'Μαρ', 'Απρ', 'Μαϊ', 'Ιούν', 'Ιούλ', 'Αυγ.', 'Σεπ', 'Οκτ', 'Νοε', 'Δεκ'
    ],
    getColor(color) {
        const colors = {
            '#7367f0': "light-primary",
            '#82868b': "light-secondary",
            '#28c76f': "light-success",
            '#ff9f43': "light-warning",
            '#ea5455': "light-danger",
            '#00cfe8': "light-info",
        }
        return colors[color];
    },

    getCustomLight(type, color = null) {
        const colors = [
            {value: '#151cc3', text: 'blue', preview: 'Μπλε',active:true},
            {value: '#c31565', text: 'magento', preview: 'Φούξια',active:true},
            {value: '#158dc3', text: 'ocean', preview: 'Γαλάζιο',active:true},
            {value: '#138e0f', text: 'green', preview: 'Πράσινο',active:true},
            {value: '#696f4f', text: 'green5', preview: 'Καφέ',active:true},
            {value: '#a26717', text: 'orange', preview: 'Πορτοκάλι',active:true},
            {value: '#1e3125', text: 'green10', preview: 'Γκρι',active:true},
            {value: '#000000', text: 'black', preview: 'Μαύρο',active:true},
        ];

        if (type === 'all') {
            return colors;
        }
        return colors;
    },
    truncateString(str, num = 80) {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    },
    abs(value) {
        return Math.abs(value)
    },

    getTypeNotification(value) {
        const type = {
            'project_task': "TASKS",
            'project': "ΕΡΓΑ",
            'todo': "TODOS",
            'event': "ΗΜΕΡΟΛΟΓΙΟ",
        }
        return type[value];
    },

    getColorNotification(value) {
        const color = {
            'project_task': "light-warning",
            'project': "light-success",
            'todo': "light-primary",
            'event': "light-danger",
        }
        return color[value];
    },

    removeBreadcrumb(){
        const test = document.querySelector('.breadcrumb')


        let contain = test.lastChild.classList.contains('custom')
        if (contain) {
            test.removeChild(test.lastChild);
        }
    },

    getIconNotification(value) {
        const icon = {
            'project_task': "LayersIcon",
            'project': "ClipboardIcon",
            'todo': "CheckSquareIcon",
            'event': "CalendarIcon",
        }
        return icon[value];
    }


}