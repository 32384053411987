import Vue from 'vue'
import {ModalPlugin, ToastPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueTippy, {TippyComponent} from "vue-tippy";
import "vue-pdf-app/dist/icons/main.css";
import VuePdfApp from "vue-pdf-app";
import logoutPlugin from 'vue-auto-logout'
import DatePicker from 'vue2-datepicker';

import '@core/scss/base/datapicker.scss';

import 'vue2-datepicker/locale/el';

import 'verte/dist/verte.css';
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import 'sweetalert2/dist/sweetalert2.min.css';
import vueDebounce from 'vue-debounce'
import flatpickr from "flatpickr";
import VueflatPickr from 'vue-flatpickr-component'
import {Greek} from "flatpickr/dist/l10n/gr.js";


import vClickOutside from 'v-click-outside'


import money from 'v-money'
// Axios Mock Adapter
import '@/@fake-db/db'
import useAppConfig from "@core/app-config/useAppConfig";
import moment from "moment";
import axiosIns from "@/libs/axios";
import jwtDefaultConfig from "@core/auth/jwt/jwtDefaultConfig";
import useJwt from "@/auth/jwt/useJwt";

flatpickr.localize(Greek); // default locale is now Russian
flatpickr.setDefaults({
    allowInput:true,
})
// register directive v-money and component <money>
Vue.use(money, {precision: 4})


Vue.use(vClickOutside)
Vue.use(VueflatPickr)


Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);
Vue.component("vue-pdf-app", VuePdfApp);
const logoutOptions = {
    stagnateTime: 30 * 60 * 1000,
    detectTime: 30 * 60,
    enable:true,
    keyName:'access_token'
}
Vue.use(logoutPlugin, logoutOptions)

Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(vueDebounce)
Vue.use(DatePicker)

// Vue.use(VueTheMask)
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

if (process.env.NODE_ENV === "development") {

}

const {skin} = useAppConfig()
new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    data: {
        items: [],
    },
    methods: {
        async loadData() {
            if (localStorage.getItem("access_token")) {
                await this.$store.dispatch('fetchNotification');

            }
        },
        changeMode() {
            var beginningTime = moment('7:00am', 'h:mma');
            var endTime = moment('7:00pm', 'h:mma');

            const currentTime = moment();

            const isBefore = currentTime.isBefore(endTime);
            const isAfter = currentTime.isAfter(beginningTime);


            if (isBefore && isAfter) {
                console.log('light')
                skin.value = 'light'
                // localStorage.setItem("vuexy-skin", 'light');
            } else {
                console.log('dark')
                skin.value = 'dark'
                // localStorage.setItem("vuexy-skin", 'dark');
            }

        }
    },
    async mounted() {

        setTimeout(()=>{
            if (store.getters.getNightMode) {
                this.changeMode();
            }
        },1200)
        setInterval(async function () {
            await this.loadData()
        }.bind(this), 300000);

        setInterval(async function () {
            const response = await axiosIns.post(jwtDefaultConfig.refreshEndpoint)
            localStorage.setItem(jwtDefaultConfig.storageTokenKeyName, response.data.access_token)
        }.bind(this),  30 * 60 * 1000);

        setInterval(async function () {
            if (store.getters.getNightMode) {
                this.changeMode();
            }
        }.bind(this), 5000 );



    },
    async created() {



    }
}).$mount('#app')