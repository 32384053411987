import axiosIns from "@/libs/axios";

const state = {
    mail: [],
}

const getters = {
    getMail: (state) => (state.mail),
}

const actions = {
    async fetchMail({commit}) {
        const response = await axiosIns.get(`/user-emails/ypoloipo-reminder`)
        commit('SET_MAIL', response.data)
    },
    async saveMail({commit},payload) {
        const response = await axiosIns.put(`/user-emails/ypoloipo-reminder?content=${payload}`)
    },
    async resetMail({commit},payload) {
        const response = await axiosIns.delete(`/user-emails/ypoloipo-reminder`)
        commit('SET_MAIL', response.data)
    },
    async sendMail({commit}, data) {
         await axiosIns.post(`/send-ypoloipo-email`, data)
    },



}

const mutations = {
    SET_MAIL: (state, data) => (state.mail = data),
    SET_TODO: (state, data) => (state.mail = data),
    // UPDATE_CONTACT_CATEGORIES: (state, data) => _.find(state.contactCategories, {id: data.id}).title = data.title,
    // DELETE_CONTACT_CATEGORY: (state, data) => state.contactCategories.splice(data.index, 1)
}

export default {state, getters, actions, mutations}