import axiosIns from "@/libs/axios";

// const doy = require('@/store/mock/doy.json')
//
// const nomos = require('@/store/mock/nomos.json')

const state = {
    user: {},
    errors: {}
}


const getters = {
    getUserCompany: (state) => state.user,
    getErrorsCompany: (state) => state.errors,


}

const actions = {

    async fetchContact({commit}, id) {
        const response = await axiosIns.get(`/contact/${id}`)
        commit('SET_CONTACT', response.data)

        return response;
    },

    async updateContact({commit}, data) {
        try {


            const payload = data.payload


            const response = await axiosIns.put(`/contact/${data.id}`, payload)

            commit('UPDATE_CONTACT', response.data)

        } catch (error) {

            commit('SET_ERROR', error.response.data.errors)
            throw error
        }


    }
}

const mutations = {
    SET_ERROR: (state, data) => state.errors = data,
    UPDATE_CONTACT: (state, data) => (state.selectedTaxOffice = data),
    SET_CONTACT: (state, data) => {
        state.user = data;
    }
}

export default {
    state, getters, actions, mutations
}