import axiosIns from "@/libs/axios";
import _ from "lodash"

const state = {
    partners: [],
    allPartners: [],
    notificationsSettings: {},
}

const getters = {
    getPartners: (state) => (state.partners),
    getAllPartners: (state) => (state.allPartners.data),
    getNotificationSettings: (state) => (state.notificationsSettings),
}

const actions = {
    async fetchPartners({commit}, page) {
        const response = await axiosIns.get(`/partner?page=${page}`)
        commit('SET_PARTNERS', response.data)
    },
    async fetchAllPartners({commit}) {
        const response = await axiosIns.get(`/partner`,{params: {rpp: 100}})
        commit('SET_ALL_PARTNERS', response)
    },
    async deletePartner({commit}, data) {

        axiosIns.delete(`/partner/${data.item.id}`)
            .then(response => {
                commit('DELETE_PARTNER', data)
            })
    },
    async storePartner({commit}, data) {


        const response = await axiosIns.post(`/partner`, data)
        commit('STORE_PARTNERS', response.data)
    },
    async updatePartner({commit}, data) {
        const currentData = data;

        await axiosIns.put(`/partner/${data.id}`, data)

        commit('UPDATE_PARTNERS', currentData)
    }
}

const mutations = {
    SET_PARTNERS: (state, data) => (state.partners = data),
    SET_ALL_PARTNERS: (state, data) => (state.allPartners = data),
    STORE_PARTNERS: (state, data) => (state.partners.data.unshift(data)),
    UPDATE_PARTNERS: (state, data) => {
        const user = _.find(state.partners.data, {id: data.id})
        user.email = data.email;
        user.surname = data.surname;
        if (data.photo)
            user.photo = data.photo;
        user.name = data.name;
    },
    DELETE_PARTNER: (state, data) => state.partners.data.splice(data.index, 1)
}

export default {state, getters, actions, mutations}