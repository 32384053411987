import jwtDefaultConfig from './jwtDefaultConfig'
import router from "@/router";
import jwt from "jsonwebtoken";
import moment from "moment";


export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = {...jwtDefaultConfig}

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns, jwtOverrideConfig) {

        this.axiosIns = axiosIns
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}
        const access_token = this.getToken()

        if (access_token) {


            if (this.isTokenExpired(access_token)) {

                this.axiosIns.defaults.headers.common['Authorization'] = `${this.jwtConfig.tokenType} ${access_token}`

                this.refreshToken().then(r => {

                    this.isAlreadyFetchingAccessToken = false

                    // Update access_token in localStorage
                    this.setToken(r.data.access_token)
                    this.setRefreshToken(r.data.refreshToken)

                    this.onAccessTokenFetched(r.data.access_token)
                })
            }
        }

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            config => {
                // Get token from localStorage

                const access_token = this.getToken()


                // If token is present add it to request's Authorization Header

                if (access_token) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${access_token}`

                }
                return config
            },
            error => Promise.reject(error),
        )

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            response => response, error => {

                // const { config, response: { status } } = error
                const {config, response} = error
                const originalRequest = config

                // if (status === 401) {
                if (response && response.status === 401) {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken().then(r => {
                            this.isAlreadyFetchingAccessToken = false

                            // Update access_token in localStorage
                            this.setToken(r.data.access_token)
                            this.setRefreshToken(r.data.refreshToken)

                            this.onAccessTokenFetched(r.data.access_token)
                        })
                    }
                    const retryOriginalRequest = new Promise(resolve => {
                        this.addSubscriber(access_token => {
                            // Make sure to assign access_token according to your response.
                            // Check: https://pixinvent.ticksy.com/ticket/2413870
                            // Change Authorization header


                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${access_token}`
                            resolve(this.axiosIns(originalRequest))
                        })
                    })
                    return Promise.reject(error)
                }
                return Promise.reject(error)
            },
        )
    }

    onAccessTokenFetched(access_token) {
        this.subscribers = this.subscribers.filter(callback => callback(access_token))
    }

    isTokenExpired(token) {
        const payloadBase64 = token.split('.')[1];
        const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
        const decoded = JSON.parse(decodedJson)
        const exp = decoded.exp;
        const expired = (moment().add(20, 'm').toDate().getTime() >= exp * 1000)


        return expired
    }


    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {


        return localStorage.getItem("access_token")


    }

    getRefreshToken() {
        return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
        return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
    }

    refreshToken() {
        return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken(),
        })
    }
}
