import axiosIns from "@/libs/axios";

const state = {
    getTags: [],
}

const getters = {
    getTags: (state) => (state.getTags),
}

const actions = {
    async fetchTodos({commit}) {
        const response = await axiosIns.get(`/todo-tag`)


        commit('SET_TAG', response.data)
    },
    async storeTodos({commit}, data) {


        const response = await axiosIns.post(`/todo-tag`, data)
        commit('SET_TODO', response.data)
    },


}

const mutations = {
    SET_TAG: (state, data) => (state.getTags = data),
    SET_TODO: (state, data) => (state.getTags = data),
    // UPDATE_CONTACT_CATEGORIES: (state, data) => _.find(state.contactCategories, {id: data.id}).title = data.title,
    // DELETE_CONTACT_CATEGORY: (state, data) => state.contactCategories.splice(data.index, 1)
}

export default {state, getters, actions, mutations}