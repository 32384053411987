import axiosIns from "@/libs/axios";

const state = {
    loggedUser: {}
}

const getters = {
    getUserLogged: (state) => state.loggedUser,
}

const actions = {
    async fetchLoggedUser({commit}) {
        const response = await axiosIns.get(`/personal-details`);


        commit('LOGGED_USER', response.data)
    }

}

const mutations = {
    LOGGED_USER: (state, value) => (state.loggedUser = value),
}

export default {state, getters, actions, mutations}