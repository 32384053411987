const state = {
    calendars: [
        {
            color: 'primary',
            label: 'Σε εξέλιξη',
        },
        {
            color: 'warning',
            label: 'Σε αναμονή',
        },
        {
            color: 'success',
            label: 'Ολοκληρωμένο',
        },
        {
            color: 'danger',
            label: 'Ακυρωμένο',
        },
    ]
}

const getters = {
    getCalendars: (state) => state.calendars,
}

const actions = {}

const mutations = {}

export default {
    state, getters, actions, mutations,
}