import axiosIns from "@/libs/axios";

const state = {
    getSettingUser: [],
}

const getters = {
    getSettingUser: (state) => (state.getSettingUser),
}

const actions = {
    async fetchSettingUser({commit}) {



        const response = await axiosIns.get(`/personal-details`)


        commit('FETCH_SETTING_USER', response.data)
    },


    async storeSettingUser({commit}, data) {
        const response = await axiosIns.put(`/personal-details`, data)
        commit('SET_SETTING_USER', response.data)
    },




}

const mutations = {
    FETCH_SETTING_USER: (state, data) => (state.getSettingUser = data),
    SET_SETTING_USER: (state, data) => (state.getSettingUser = data),

}

export default {state, getters, actions, mutations}