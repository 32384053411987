
import caldoSettings from "@/caldoSettings";

export default {
  // Endpoints
  loginEndpoint: `${caldoSettings.baseUrl}/login`,
  // loginEndpoint: `${new URL(location.href).searchParams.get('page')}/api/login`,
  // registerEndpoint: '/jwt/register',
  refreshEndpoint: `${caldoSettings.baseUrl}/refresh`,
  // refreshEndpoint: `${new URL(location.href).searchParams.get('page')}/api/refresh`,
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access_token',
  storageRefreshTokenKeyName: 'refreshToken',
}
