// all data module


import axiosIns from "@/libs/axios";

const state = {
    project: [],
    projectsAll: [],
    categoriesProjectActive: [],
    aggregations: [],
    projectActive: [],
    livePayment: {
        sum: [],
        text:'',
        paid: 0,
        remaining: 0
    },
    categoriesProject: [],
};

const getters = {
    getProject: (state) => state.project,
    getProjects: (state) => state.projectsAll,
    getCategoriesProject: (state) => state.categoriesProject,
    projectActive: (state) => state.projectActive,
    getLivePaymentSum: (state) => state.livePayment.sum,
    getLivePaymentPaid: (state) => state.livePayment.paid,
    getAggregations: (state) => state.aggregations,
    getLiveText: (state) => state.text


};


const actions = {

    async fetchProject({commit}, project) {

        const response = await axiosIns.get(`/project/${project}`)


        commit('SET_PROJECT', response.data)
    },

    async fetchProjectCategories({commit}, filters) {
        const response = await axiosIns.get(`/project`, filters)

        commit('SET_CONTACTS', response.data)
    },
    async fetchProjectCategoriesActive({commit},filters) {
        const response = await axiosIns.get(`/project`, filters)
        commit('SET_CONTACTS_ACTIVE', response.data)
    },
    async fetchAllCategoriesProject({commit}, filters) {
        filters.params.rpp = 999;

        const response = await axiosIns.get(`/project`, filters)


        commit('SET_CATEGORIES_PROJECT', response.data)
    },

    async storeProjectCategory({commit}, data) {
        const response = await axiosIns.post(`/project`, data)

        commit('ADD_PROJECT_CATEGORY_ROW', response.data)


    },

    async storePaymentCategory({commit}, data) {
        const response = await axiosIns.post(`/project-payment`, data)
        // commit('ADD_PAYMENT_CATEGORY_ROW', response.data)


    },
    async deleteProject({commit}, data) {
        const response = axiosIns.delete(`/project/${data}`)
        commit('ADD_PROJECT_CATEGORY_ROW', response.data)
    },

    async getAggregations({commit}, data) {
        const response = await axiosIns.get(`/project-aggregations`)


        commit('GET_AGGREGATIONS', response.data)
    },
    updatePageSelectedOption({commit}, value = 1) {
        commit('UPDATE_PAGE_SELECTED_VALUE', value);
    },


    updateSum({commit}, value) {

        commit('UPDATE_SUM', value);
    },

    updatePaid({commit}, value) {

        commit('UPDATE_PAID', value);
    },

    // async deleteProjectCategory({commit}, data) {
    //
    //     console.log('asdasd');
    //     // axiosIns.delete(`/project/${data.item.id}`)
    //     //     .then(response => {
    //     //         commit('DELETE_PROJECT_CATEGORY', data)
    //     //     })
    // },

};

const mutations = {
    SET_PROJECT: (state, value) => (state.project = value),
    GET_AGGREGATIONS: (state, value) => (state.aggregations = value),
    SET_CONTACTS: (state, value) => (state.projectsAll = value),
    SET_CONTACTS_ACTIVE: (state, value) => (state.projectActive = value),
    SET_CATEGORIES_PROJECT: (state, value) => {
        state.categoriesProject = value.data.map(el => {
            return {
                id: el.id,
                title: el.title,
                contactId: el.contactId,
                tasks: el.tasks,
                deadline: el.deadline,
                task_completed_percentage: el.task_completed_percentage,
                pendingCost: el.pendingCost,
                contact: el.contact,
            }
        })
    },
    UPDATE_PAGE_SELECTED_VALUE: (state, value) => (state.perPageSelectedOption = value),
    ADD_PROJECT_CATEGORY_ROW: async (state, data) => {
        const response = await axiosIns.get(`/project`, {
            params: {
                "orderBy": ["id"],
                "sortBy": "desc",
                "rpp": "6",
            }
        })
        const responseGlobal = await axiosIns.get(`/project`, {
            params: {
                "orderBy": ["id"],
                "sortBy": "desc",
                "rpp": "999",
            }
        })

        state.projects = response.data;
        state.categoriesProject = responseGlobal.data.data;
    },
    UPDATE_SUM: (state, data) => state.livePayment.sum = data,
    UPDATE_PAID: (state, data) => state.livePayment.paid = data
};

export default {
    state,
    getters,
    actions,
    mutations,
};
