export default [
    {
        path: '/reports',
        name: 'reports',
        component: () => import('@/views/reports/Report'),
        meta: {
            requireAuth: true,
            pageTitle: 'Αναφορές',
        }
    },
    {
        path: '/reports/transactions?:userId?',
        name: 'reports-transactions',
        component: () => import('@/views/reports/ReportClientTransactions'),
        meta: {
            requireAuth: true,
            pageTitle: 'Καρτέλα πελάτη',
        }
    },
    {
        path: '/reports/balance',
        name: 'reports-balance',
        component: () => import('@/views/reports/ReportClientBalance'),
        meta: {
            requireAuth: true,
            pageTitle: 'Υπόλοιπα πελατών',
        }
    },
    {
        path: '/reports/clients-list',
        name: 'clients-list',
        component: () => import('@/views/reports/ReportClientList'),
        meta: {
            requireAuth: true,
            pageTitle: 'Λίστα πελατών',
        }
    },
    {
        path: '/reports/projects-list',
        name: 'projects-list',
        component: () => import('@/views/reports/ReportProjectsList'),
        meta: {
            requireAuth: true,
            pageTitle: 'Λίστα έργων',
        }
    },
    {
        path: '/reports/tasks-list',
        name: 'tasks-list',
        component: () => import('@/views/reports/ReportTasksList'),
        meta: {
            requireAuth: true,
            pageTitle: 'Λίστα εργασιών',
        }
    },
    {
        path: '/reports/expences-list',
        name: 'expences-list',
        component: () => import('@/views/reports/ReportExpencesList'),
        meta: {
            requireAuth: true,
            pageTitle: 'Λίστα εξόδων',
        }
    },
    {
        path: '/reports/income-list',
        name: 'income-list',
        component: () => import('@/views/reports/ReportIncomeService'),
        meta: {
            requireAuth: true,
            pageTitle: 'Έσοδα ανά υπηρεσία',
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/settings/Settings'),
        meta: {
            requireAuth: true,
            pageTitle: 'Ρυθμίσεις',
        }
    },
]
