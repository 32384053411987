import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import myDashboard from './routes/caldo-dashboard'
import myApps from './routes/caldo-apps'
import myOther from './routes/caldo-other'
import pages from './routes/pages'
import moment from "moment";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/', redirect: {name: 'dashboard'}},
        ...myApps,
        ...myDashboard,
        ...myOther,
        ...pages,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})


function isTokenExpired(token) {

    if (!token)
        return true
    const payloadBase64 = token.split('.')[1];
    const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
    const decoded = JSON.parse(decodedJson)
    const exp = decoded.exp;
    const expired = (moment().toDate().getTime() >= exp * 1000)


    return expired
}

router.beforeEach(async (to, from, next) => {

    let isLoggedIn = isUserLoggedIn()



    if (to.matched.some(record => record.meta.requireAuth)) {
        if (!isTokenExpired(isLoggedIn)) {
            const userData = getUserData()
            if (from.name==='proasjects-view' && from.meta.changed){
                const {isConfirmed} =   await window.swall({
                    title: 'Είστε σίγουρος?',
                    text: "Θέλετε πραγματικά να φύγετε; Έχετε μη αποθηκευμένες αλλαγές!",
                    type: 'warning',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#7367f0',
                    cancelButtonColor: '#82868b',
                    reverseButtons:true,
                    confirmButtonText: 'Ναι!',
                    cancelButtonText: 'Όχι!',
                    buttonsStyling: true,
                    animation: true
                });
                if (isConfirmed) {
                    next();
                } else {
                    next(false);
                }
            }else{
                next()
            }
        } else {
            router.replace({name: 'auth-login'})
        }
    } else {
        next()
    }

})

export default router
