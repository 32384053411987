export default [
    {
        path: "/apps/contacts",
        name: "contacts",
        meta: {
            requireAuth: true,
        },
        component: () => import("@/views/contacts/contacts-list/Contacts"),
    },
    {
        path: "/apps/contacts/edit/:userId",
        name: "contacts-edit",
        component: () => import("@/views/contacts/contacts-edit/ContactsEdit"),
        meta: {
            pageTitle: 'Επεξεργασία πελάτη',
            requireAuth: true,
            breadcrumb: [
                {
                    text: 'Λίστα', to: '/apps/contacts',
                },
                {
                    text: 'Επεξεργασία',
                    active: true,
                }
            ],
        },
    },
    {
        path: "/apps/contacts/view/:userId",
        name: "contacts-view",
        component: () => import("@/views/contacts/contacts-view/ContactsView"),
        meta: {
            requireAuth: true,
            pageTitle: 'Προβολή πελάτη',
            contentClass: 'email-application',
            breadcrumb: [
                {
                    text: 'Λίστα', to: '/apps/contacts',
                },
                {
                    text: '',
                    active: true,
                }
            ]
        },
    },
    {
        path: "/apps/projects",
        name: "projects",
        component: () => import("@/views/projects/projects-list/Projects"),
        meta: {
            requireAuth: true,
            contentClass: "todo-application",
        },

    },
    {
        path: "/apps/projects/view/add",
        name: "projects-add",
        component: () => import("@/views/projects/projects-view/ProjectsView"),
        meta: {
            pageTitle: 'Προβολή έργου',
            requireAuth: true,
            breadcrumb: [
                {
                    text: 'Λίστα', to: '/apps/projects',
                }
            ]
        },
    },
    {
        path: "/apps/projects/view/project/:projectId",
        name: "projects-view",
        component: () => import("@/views/projects/projects-view/ProjectsView"),
        meta: {
            pageTitle: 'Προβολή έργου',
            requireAuth: true,
            breadcrumb: [
                {
                    text: 'Λίστα', to: '/apps/projects',
                }
            ]
        },
    },


    {
        path: "/apps/calendar",
        name: "calendar",
        component: () => import("@/views/calendar/Calendar"),
        meta: {
            requireAuth: true,
            contentClass: "todo-application",
        },
    },
    {
        path: "/apps/to-do",
        name: "todo",
        component: () => import("@/views/todo/Todo"),
        meta: {
            requireAuth: true,
            contentRenderer: "sidebar-left",
            contentClass: "todo-application",
            navActiveLink: 'apps-todo',
        },
    },
    {
        path: '/apps/to-do/:filter',
        name: 'apps-todo-filter',
        component: () => import('@/views/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
        },


    },
    {
        path: '/apps/to-do/tag/:tag',
        name: 'apps-todo-tag',
        component: () => import('@/views/todo/Todo.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            navActiveLink: 'apps-todo',
        },
    },
    {
        path: "/apps/expences",
        name: "expences",
        component: () => import("@/views/expences/Expences.vue"),
        meta: {
            requireAuth: true,
            contentClass: "expences-application",
        },

    },
    {
        path: "/apps/notifications",
        name: "notifications",
        component: () => import("@/views/notification/notification-all.vue"),
        meta: {
            requireAuth: true,
            contentClass: "notifications-application",
        },

    },
    {
        path: "/apps/support",
        name: "support",
        component: () => import("@/views/footer/Support.vue"),
        meta: {
            requireAuth: true,
            contentClass: "notifications-application",
        },

    },
    {
        path: "/apps/subscription",
        name: "subscription",
        component: () => import("@/views/footer/Subscription.vue"),
        meta: {
            requireAuth: true,
            contentClass: "notifications-application",
        },

    },
];
