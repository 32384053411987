<template>
   <div
	   id="app"
	   class="h-100"
	   :class="[skinClasses]"
   >
	  <component :is="layout">
		 <router-view />
	  </component>


	  <v-tour name="myTour"
              :callbacks="{
    onFinish: onSkip,
    onSkip: onSkip,
    onStop: onSkip,
  }"
			  v-if="showTour"
			  :steps="steps"
			  :options="myOptions"></v-tour>

	  <scroll-to-top v-if="enableScrollToTop" />
   </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import {$themeColors, $themeBreakpoints, $themeConfig} from '@themeConfig'
import {provideToast} from 'vue-toastification/composition'
import {watch} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import {useWindowSize, useCssVar} from '@vueuse/core'

import store from '@/store'
import router from "@/router";

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
   components: {

	  // Layouts
	  LayoutHorizontal,
	  LayoutVertical,
	  LayoutFull,

	  ScrollToTop,
   },
   data() {
	  return {
          showTour : false,
		 show: localStorage.getItem('userData'),
		 myOptions: {
			useKeyboardNavigation: false,
			labels: {
			   buttonSkip:  this.$t('generic.skip'),
			   buttonPrevious: this.$t('generic.previous'),
			   buttonNext: this.$t('generic.next'),
			   buttonStop: this.$t('generic.close')
			}
		 },
		 steps: [
			{
			   target: '#tour-settings',  // We're using document.querySelector() under the hood
			   content: '<strong>Ξεκινήστε εδώ!</strong><br>Συμπληρώστε όλα τα προσωπικά σας στοιχεία ή της επιχείρησής σας.',
			   params: {
			 	  placement: 'left'
				}
			},
			{
			   target: '#tour-clients',
			   content: '<strong>Προσθέστε Πελάτες</strong><br>Ξεκινήστε να καταχωρείτε τους πελάτες σας από εδώ',
			   params: {
			 	  placement: 'left'
				}
			},
			{
			   target: '#bookmark-0',
			   content: '<strong>Quick actions</strong><br>Προσθέστε με ευκολία Έργα, Πελάτες κτλ, από οποιοδήποτε σημείο και αν βρίσκεστε.',
			   params: {
			 	  placement: 'bottom'
				}
			},
			{
			   target: '#tour-support',
			   content: '<strong>Συνδρομή & Υποστήριξη</strong><br>Δείτε από εδώ πότε λήγει η συνδρομή σας ή επικοινωνήστε μαζί μας για κάθε σας απορία.'
			},
			// {
			//    target: '[data-v-step="2"]',
			//    content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
			//    params: {
			// 	  placement: 'top'
			//    }
			// }
		 ]
	  }
   },


   // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
   // Currently, router.currentRoute is not reactive and doesn't trigger any change
   computed: {
	  layout() {
		 if (this.$route.meta.layout === 'full') return 'layout-full'
		 return `layout-${this.contentLayoutType}`
	  },
	  contentLayoutType() {
		 return this.$store.state.appConfig.layout.type
	  },
   },
   beforeCreate() {
	  // Set colors in theme
	  const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

	  // eslint-disable-next-line no-plusplus
	  for (let i = 0, len = colors.length; i < len; i++) {
		 $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
	  }

	  // Set Theme Breakpoints
	  const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

	  // eslint-disable-next-line no-plusplus
	  for (let i = 0, len = breakpoints.length; i < len; i++) {
		 $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
	  }

	  // Set RTL
	  const {isRTL} = $themeConfig.layout
	  document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
   },
	methods:{
       async onSkip() {
            await this.$store.dispatch("setDynamicPreferences", ['showTour', false]);
        },
       
	},
	async created() {
        await this.$store.dispatch('fetchDynamicPreferences')
        this.showTour = await this.$store.getters.getDynamicPreference['showTour'];

    },
    setup() {
	  const {skin, skinClasses} = useAppConfig()
	  const {enableScrollToTop} = $themeConfig.layout

	  // If skin is dark when initialized => Add class to body
	  if (skin.value === 'dark') document.body.classList.add('dark-layout')

	  // Provide toast for Composition API usage
	  // This for those apps/components which uses composition API
	  // Demos will still use Options API for ease
	  provideToast({
		 hideProgressBar: true,
		 closeOnClick: false,
		 closeButton: false,
		 icon: false,
		 timeout: 3000,
		 transition: 'Vue-Toastification__fade',
	  })

	  // Set Window Width in store
	  store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
	  const {width: windowWidth} = useWindowSize()
	  watch(windowWidth, val => {
		 store.commit('app/UPDATE_WINDOW_WIDTH', val)
	  })

	  return {
		 skinClasses,
		 enableScrollToTop,
	  }
   },
}
</script>

<style>

.v-step__button {
   color: black !important;
}
.v-step__button {
	margin: 0 12px 0 0 !important;
	padding: 0 !important;
}
.v-step__button:hover {
	color:#7367f0 !important;
}
</style>
