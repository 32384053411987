import Vue from 'vue'

// axios
import axios from 'axios'
import caldoSettings from "@/caldoSettings";
const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: caldoSettings.baseUrl,
    // timeout: 3000,
    delayed: true
    // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
