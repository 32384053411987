import axiosIns from "@/libs/axios";

const state = {
    notifications: [],
}

const getters = {
    getNotification: (state) => (state.notifications),
}

const actions = {
    async fetchNotification({commit}) {
        const response = await axiosIns.get(`/notifications`)

        commit('FETCH_NOTIFICATION', response.data)
    },

    async readNotification({commit}, hash) {
        const response = await axiosIns.get(`/notifications/${hash}/1`)

        // commit('FETCH_NOTIFICATION', response.data)
    },




}

const mutations = {
    FETCH_NOTIFICATION: (state, data) => (state.notifications = data),

}

export default {state, getters, actions, mutations}


