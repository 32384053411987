// all data module


import axiosIns from "@/libs/axios";

const state = {
    contact: [],
    contacts: [],
    allContacts: [],
    categories: [],
    tableColumns: [
        {key: "name", label: "Πελατης", sortable: true},
        {key: "financial", label: "#",},
        {key: "category.title", label: "Κατηγορια", sortable: true},
        {key: "company.vat", label: "Α.Φ.Μ.", sortable: false},
        {key: "email", label: "E-mail", sortable: false},
        {key: "mobile", label: "Κινητο", sortable: false},
        // {key: "telephone", label: "Σταθερό", sortable: true},
        {key: "actions", label: ""},
    ],
};

const getters = {
    getContact: (state) => state.contact,
    getContacts: (state) => state.contacts,
    getAllContacts: (state) => state.allContacts,
    getCategories: (state) => state.categories,

    getContactTableColumns: (state) => state.tableColumns,
};


const actions = {
    async fetchContact({commit}, contactId) {


        const response = await axiosIns.get(`/contact/${contactId}`)
        commit('SET_CONTACT', response.data)
    },
    async fetchContactCategories({commit}, filters) {
        const response = await axiosIns.get(`/contact`, filters)
        commit('SET_CONTACTS', response.data)
    },
    async fetchAllCategories({commit}, filters) {
        filters.params.rpp = 999;
        const response = await axiosIns.get(`/contact`, filters)


        commit('SET_CATEGORIES', response.data)
    },
    async fetchAllContacts({commit}) {

        const response = await axiosIns.get(`/contact`, {
            params: {
                orderBy: ["surname"],
                rpp: 999
            }
        })


        commit('SET_ALL_CONTACTS', response.data)
    },

    async storeContactCategory({commit}, data) {
        const response = await axiosIns.post(`/contact`, {
            name: data.name,
            surname: data.surname,
            email: data.email,
            mobile: data.mobile,
            telephone: data.telephone
        })
        commit('ADD_CONTACT_CATEGORY_ROW', response.data)
        if (response)
            return response;
    },
    updatePageSelectedOption({commit}, value = 1) {
        commit('UPDATE_PAGE_SELECTED_VALUE', value);
    },

    async deleteContactCategory({commit}, data) {

        axiosIns.delete(`/contact/${data.item.id}`)
            .then(response => {
                commit('DELETE_CONTACT_CATEGORY', data)
            })
    },

};

const mutations = {
    SET_CONTACT: (state, value) => (state.contact = value),
    SET_CONTACTS: (state, value) => (state.contacts = value),
    SET_CATEGORIES: (state, value) => {
        state.categories = value.data.map(el => {
            return {
                id: el.category?.id,
                title: el.category?.title,
            }
        })
    },
    SET_ALL_CONTACTS: (state, value) => state.allContacts = value.data,
    UPDATE_PAGE_SELECTED_VALUE: (state, value) => (state.perPageSelectedOption = value),
    ADD_CONTACT_CATEGORY_ROW: async (state, data) => {
        const response = await axiosIns.get(`/contact`, {
            params: {
                orderBy: ["surname"],
            }
        })
        const responseGlobal = await axiosIns.get(`/contact`, {
            params: {
                "orderBy": ["surname"],
                "rpp": 999,

            }
        })
        state.contacts = response.data;
        state.allContacts = responseGlobal.data.data;
    },
    DELETE_CONTACT_CATEGORY: async (state, data) => {
        const response = await axiosIns.get(`/contact`, {
            params: {
                orderBy: ["surname"],
            }
        })
        const responseGlobal = await axiosIns.get(`/contact`, {
            params: {
                orderBy: ["surname"],
                "rpp": 999,
            }
        })
        state.contacts = response.data;
        state.allContacts = responseGlobal.data.data;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
