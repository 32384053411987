import axiosIns from "@/libs/axios";
import caldoSettings from "@/caldoSettings";

const state = {

    generalSettings: [],
    payments: caldoSettings.payments,
    subscription:[]


}

const getters = {
    getGeneralSettings: (state) => state.generalSettings,
    getGeneralCalendar: (state) => state.generalSettings.calendar,
    getGeneralTodo: (state) => state.generalSettings.todo,
    getPayment: (state) => state.payments,
    getSubscription: (state) => state.subscription,

}

const actions = {
    async fetchGeneralSettings({commit}) {
        const response = await axiosIns.get(`/general-settings`)
        commit('SET_GENERAL_SETTINGS', response.data)
    },

    async fetchSubscription({commit}) {
        const response = await axiosIns.get(`/tenant-info`)
        commit('SET_TENANT_INFO', response.data)
    },
}


const mutations = {
    SET_GENERAL_SETTINGS: (state, data) => (state.generalSettings = data),
    SET_TENANT_INFO: (state, data) => (state.subscription = data),
}

export default {
    state, getters, actions, mutations,
}