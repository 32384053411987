import axiosIns from "@/libs/axios";

const state = {
    expense: [],
}

const getters = {
    getSettingExpense: (state) => (state.expense),
}

const actions = {
    async fetchExpenseSetting({commit}) {



        const response = await axiosIns.get(`/expense-category`)
        commit('SET_SETTING_EXPENSE', response.data)
    },


    async storeExpense({commit}, data) {
         await axiosIns.post(`/expense-category`, data)
    },



}

const mutations = {
    SET_SETTING_EXPENSE: (state, data) => (state.expense = data),
    SET_EXPENSE: (state, data) => (state.expense = data),
}

export default {state, getters, actions, mutations}