import axiosIns from "@/libs/axios";

const state = {
    getCalendar: [],
}

const getters = {
    getCalendar: (state) => (state.getCalendar),
}

const actions = {
    async fetchCalendar({commit}) {
        const response = await axiosIns.get(`/calendar`)


        commit('FETCH_CALENDAR', response.data)
    },


    async storeCalendar({commit}, data) {


        const response = await axiosIns.post(`/calendar`, data)
        commit('SET_CALENDAR', response.data)
    },


}

const mutations = {
    FETCH_CALENDAR: (state, data) => (state.getCalendar = data),
    SET_CALENDAR: (state, data) => (state.getCalendar = data),

}

export default {state, getters, actions, mutations}