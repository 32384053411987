import axiosIns from "@/libs/axios";

const doy = require('@/store/mock/doy.json')
//
const areas = require('@/store/mock/nomos.json')

const state = {
    company: {},
    errors: {}
}


const getters = {
    getSettingCompany: (state) => state.company,
    getErrorsCompanys: (state) => state.errors,
    getDoy: () => doy,
    getAreas: () => areas,


}

const actions = {

    async fetchSettingContact({commit}, id) {
        const response = await axiosIns.get(`/company-details`)
        commit('SET_SETTING_CONTACT', response.data)

        return response;
    },

    async updateSettingContact({commit}, data) {
        try {


            // const convertData = {
            //     "name": data.name,
            //     "trademark": data.trademark,
            //     "activity": data.activity,
            //     "vat": data.vat,
            //     "doy": data.doy.name,
            //     "address": data.address,
            //     "state": data.state.name,
            //     "area": data.area,
            //     "postcode": data.postcode,
            //     "telephone": data.telephone,
            //     "email": data.email,
            //     "photo": data.photo
            // }


            const response = await axiosIns.put(`/company-details`, data)

            commit('UPDATE_SETTING_CONTACT', response.data)

        } catch (error) {


            commit('SET_ERROR', error.response.data.errors)
            throw error
        }


    }
}

const mutations = {
    SET_ERROR: (state, data) => state.errors = data,
    UPDATE_SETTING_CONTACT: (state, data) => (state.selectedTaxOffice = data),
    SET_SETTING_CONTACT: (state, data) => {

        state.company = data;
    }
}

export default {
    state, getters, actions, mutations
}