import axiosIns from "@/libs/axios";

const state = {
    preferences: {},
    dynamicPreferences: {}
}

const getters = {
    getSidebarOpen: (state) => (state.preferences.sidebarOpen),
    getNightMode: (state) => (state.preferences.nightModeActive),
    getCompanyInfoBoxOpen: (state) => (state.preferences.companyInfoboxOpen),
    getMondayNotifications: (state) => (state.preferences.mondayNotifications),
    getTasksNotifications: (state) => (state.preferences.tasksNotifications),
    getDynamicPreference: (state) => (state.dynamicPreferences),

}

const actions = {
    async fetchPreferencesSettings({commit}, page) {
        const response = await axiosIns.get(`/user-preferences`)

        commit('SET_PREFERENCES_SETTING_', response.data)
    },
    changeNightMode({commit},mode){
        commit('SET_NIGHT_MODE', mode)
    },


    async fetchDynamicPreferences({commit}, page) {
        const response = await axiosIns.get(`/user-dynamic-preferences`)

        commit('SET_DYNAMIC_PREFERENCES', response.data)
    },

    async setDynamicPreferences({commit}, data) {
        console.log(data)
        const response = await axiosIns.post(`user-dynamic-preferences`,{
            [data[0]]:data[1]
        })

        commit('SET_DYNAMIC_PREFERENCES', response.data)
    },
}


const mutations = {


    SET_PREFERENCES_SETTING_: (state, data) => (state.preferences = data),
    SET_NIGHT_MODE: (state, data) => (state.preferences.nightModeActive = data),
    SET_DYNAMIC_PREFERENCES: (state, data) => (state.dynamicPreferences = data),


}

export default {state, getters, actions, mutations}