import axiosIns from "@/libs/axios";

const state = {
    project: [],
}

const getters = {
    getSettingProject: (state) => (state.project),
}

const actions = {
    async fetchProjectSetting({commit}) {


        const response = await axiosIns.get(`/project-category`)


        commit('SET_SETTING_PROJECTS', response.data)
    },


    async storeProject({commit}, data) {
        const response = await axiosIns.post(`/project-category`, data)
        // commit('SET_PROJECT', response.data)
    },

    async updateProject({commit}, data) {
        const response = await axiosIns.post(`/project`, data)
        commit('UPDATE_PROJECT', response.data)
    },


}

const mutations = {
    SET_SETTING_PROJECTS: (state, data) => (state.project = data),
    SET_PROJECT: (state, data) => (state.project = data),
    UPDATE_PROJECT: (state, data) => (state.project = data),

}

export default {state, getters, actions, mutations}