export default [
    {
        path: '/main/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/Dashboard'),
        meta: {
            requireAuth: true,
        },
    },
]
